import { ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Accordion,
  AccordionTab,
  AccordionTabChangeEvent,
} from 'primereact/accordion';

import {
  SURVEILLANCE_MEDIA_LABEL,
  SurveillanceMedia,
} from '../../../../Models/ReportsResponse';
import { DetailsComponentProps, PanelState } from '../../Models';

import styles from './DetailsComponent.module.scss';

const DetailsComponent = ({
  className,
  contentPanelState,
  rightPanelState,
  companyName,
  details,
  hidden,
}: DetailsComponentProps): ReactNode => {
  const [activeIndex, setActiveIndex] = useState<number | number[]>(0);

  useEffect(() => setActiveIndex(0), [details]);

  if (hidden) {
    return <></>;
  }
  return (
    <div className={clsx(styles.container, className)}>
      {details && (
        <div className={styles.content}>
          <div className={styles.recordDetailsContainer}>
            <dl>
              <div
                className={clsx({
                  'direction--column':
                    contentPanelState === PanelState.expanded ||
                    rightPanelState === PanelState.collapsed,
                })}
              >
                <div className={clsx(styles.recordDetailsContainerMultiline)}>
                  <dt>Company</dt>
                  <dd>{companyName}</dd>
                </div>
                <div
                  className={clsx(styles.dateAndMediaRow, {
                    flex:
                      contentPanelState !== PanelState.expanded &&
                      rightPanelState === PanelState.expanded,
                  })}
                >
                  <div className='direction--column'>
                    <dt>Date</dt>
                    <dd>
                      {details.startTime.toFormat('dd LLL yyyy, HH:mm:ss ZZZZ')}
                    </dd>
                  </div>
                  <div className='direction--column'>
                    {details.media && (
                      <>
                        <dt>Media</dt>
                        <dd>
                          {
                            SURVEILLANCE_MEDIA_LABEL[
                              details.media as SurveillanceMedia
                            ]
                          }
                        </dd>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <dt>User</dt>
              <div className={clsx(styles.recordDetailsContainerMultiline)}>
                {details.username && (
                  <div>
                    <div className={clsx(styles.lineParticipantsLeftColUser)}>
                      Name:
                    </div>
                    <dd className={clsx(styles.lineParticipantsRightCol)}>
                      {details.username}
                    </dd>
                  </div>
                )}
                {details.metadata.number && (
                  <div>
                    <div className={clsx(styles.lineParticipantsLeftColUser)}>
                      Number:
                    </div>
                    <div>
                      <dd className={clsx(styles.lineParticipantsRightCol)}>
                        {details.metadata.number}
                      </dd>
                    </div>
                  </div>
                )}
                {details.metadata.email && (
                  <div>
                    <div className={clsx(styles.lineParticipantsLeftColUser)}>
                      Email:
                    </div>
                    <div>
                      <dd className={clsx(styles.lineParticipantsRightCol)}>
                        {details.metadata.email}
                      </dd>
                    </div>
                  </div>
                )}
              </div>
              <Accordion
                className={clsx(
                  styles.recordDetailsContainerMultiline,
                  styles.recordDetailsContainerCounterpart
                )}
                activeIndex={activeIndex}
                onTabChange={(e: AccordionTabChangeEvent) =>
                  setActiveIndex(e.index)
                }
              >
                <AccordionTab header='Counterpart'>
                  {details.line && (
                    <div>
                      <div className={clsx(styles.lineParticipantsLeftCol)}>
                        Line:
                      </div>
                      <dd className={clsx(styles.lineParticipantsRightCol)}>
                        {details.line}
                      </dd>
                    </div>
                  )}
                  <div>
                    {details.line && (
                      <div className={clsx(styles.lineParticipantsLeftCol)}>
                        Participants:
                      </div>
                    )}
                    <dd className={clsx(styles.lineParticipantsRightCol)}>
                      {details.participants}
                    </dd>
                  </div>
                </AccordionTab>
              </Accordion>
            </dl>
          </div>
        </div>
      )}
    </div>
  );
};

export { DetailsComponent };
export default DetailsComponent;

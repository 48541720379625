import { useRef, useState } from 'react';
import { useNavigate, NavigateFunction } from "react-router-dom";
import { clsx } from 'clsx';

import { Button } from 'primereact/button';

import { eventBus } from "server/EventBus";
import { useDoubleClick } from 'helpers/Hooks/DoubleClick';

import LimitedLenghtInput from 'components/LimitedLenghtInput';

import { OpenWorksheet } from '../Models/WorksheetResponse';
import { WorksheetSignalMessageEventTypes, MutationType } from '../Models/Enums';
import { MutationAction } from '../OpenWorksheets';
import { useRenameWorksheet } from '../Services/WorksheetHooks';

import styles from './WorksheetTab.module.scss';

interface IWorksheetTab {
	sheet: OpenWorksheet
	className: string;
	dispatcher: (action: MutationAction) => void;
	disabled: boolean;
	currentRoute: string;
}

export const WorksheetTab = (props: IWorksheetTab): JSX.Element => {

	const { sheet, className, dispatcher, disabled, currentRoute } = props;
	const { id: worksheetId, store, name: currentName, isInvalid } = sheet;

	const foo = useRef(null);
	const navigate: NavigateFunction = useNavigate();
	const [ isEditingMode, setIsEditingMode ] = useState<Boolean>(false);

	const { renameSheet } = useRenameWorksheet(sheet.store);

	useDoubleClick({
		ref: foo,
		onDoubleClick: (e) => setIsEditingMode(true),
		onSingleClick: (e) => navigate(`${currentRoute}${sheet.id}`)
	})

	const process = async (name: string | boolean) => {

		//	we only want to trigger a renaming of the worksheet IF its
		//	acutally changed
		if (name && (name as string).trim() && name !== currentName) {

			//	optimistic update of the current local state means we
			//	dont have to wait for the worksheet update signalR message
			//	to observe the new name in the UI (#1013)
			dispatcher({type: MutationType.Change, payload: worksheetId, name: name as string});

			await renameSheet({ worksheetId, store, name: name as string });
		}

		setIsEditingMode(false);
	}

	return <div className={clsx(className, {
			[`worksheet-tab--editing-mode ${styles.editingMode}`]: isEditingMode
		})}>
		{ sheet &&
		<>
			{ !isInvalid &&
				<>
					{ isEditingMode
							? <LimitedLenghtInput
									currentValue={sheet.name}
									callback={process}
									maxlength={30}
									showCounter={true}
									preventInputOverflow={true}
									showError={false}
								/>
							: <Button
									ref={foo}
									size="small"
									text
									className='transparent plain-text'
									>
									<label title={sheet.name}>{sheet.name}</label>
								</Button>
					}
				</>
			}
			<Button size="small" text
				icon="iconoir-xmark icon--tiny"
				className='transparent'
				disabled={disabled}
				onClick={() => {
					eventBus.dispatch(WorksheetSignalMessageEventTypes.WORKSHEET_CLOSED, sheet.id);

					dispatcher({type: MutationType.Close, payload: sheet.id})
				}}
			/>
		</>
		}
	</div>
}
export default WorksheetTab;
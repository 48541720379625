import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, Outlet, useMatches, useOutletContext, useNavigate } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";
import { CSSTransition } from 'react-transition-group';
import { PrimeReactProvider, APIOptions } from 'primereact/api';

import { setCookie } from "typescript-cookie"
import clsx from "clsx";
import axios from "axios";

import { useAuth0 } from "@auth0/auth0-react";
import * as signalR from "@microsoft/signalr";


// import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "./styles/themes/themes/obx/theme.scss";
//  TODO - we can loose this once iconir is fully integrated…
import 'primeicons/primeicons.css';

import { Dialog, DialogProps } from 'primereact/dialog';

import { eventBus } from "server/EventBus";
import { SignalRApi } from "server/signalR-api";
import {
  DialogPosition,
  DialogSize,
  GlobalDialogDisplayEvents,
  IDialogDisplayEventDetails,
} from 'models/shared/DialogDisplay';
import TopMenu from 'components/TopMenu';
import Loader from "components/Loader";
import { ProfileResult } from "components/OBXUser/Model/ProfileResult";
import { useLoadUserSettings, useLoggedInUser } from 'components/OBXUser/Services/ProfileHooks';
import { getFirstAvailableRoute, getTopMenuRoutes, hasUserAccess } from 'components/TopMenu/Models/TopMenuRoutes';
import { UISettings } from 'components/OBXUser/Model/Enums';

import MobileVersionInDevelopmentRibbon from "components/Ribbons/Templates/MobileVersionInDevelopment";
import AlphaReleaseRibbon from "components/Ribbons/Templates/AlphaRelease";

import ToastMessage, {
  ReplaceFnProps,
  ToastMessageRef,
} from 'components/ToastMessage';
import AllWorksheets from 'components/Worksheets/AllWorksheets';
import { WorksheetStores, WorksheetPanelDisplayEvent } from 'components/Worksheets/Models/Enums';
import { SignalRWorksheetPanel } from 'components/Worksheets/Models/Events';

import Ribbons from "components/Ribbons";
import { RibbonTypes } from "components/Ribbons/Models/RibbonTypes";
import { SecurityRights } from 'components/OBXUser/Model/SecurityRightsEnum';
import NoInternetConnection from 'components/Ribbons/Templates/NoInternetConnection/NoInternetConnection';
import ErrorToast from 'components/Errors/ErrorToast';
import { stripUUID } from 'helpers/Utils/string';

import { ApplicationInternalEventTypes } from 'models/shared/Enums';

import "./App.scss";

type RouteData = {
	accessCode: SecurityRights | SecurityRights[];
	heading: string;
	hideMenu?: boolean;
	ribbons?: RibbonTypes[];
} | undefined;

interface AppParams {
	config: any;
}

interface Socket {
	signal: SignalRApi;
}

function DialogHeaderIcons(props: DialogProps): ReactNode {
	return (
		<div>
			<div
				id='close-dialog-button'
				className='iconoir-xmark icon--small icon--hover-ob-orange dialog-close-button'
				onClick={props.onHide}
			/>
		</div>
	);
}

function App(props: AppParams) {

	// PrimeReact.ripple = true;

	const { config } = props;


	const [showMenu, setShowMenu] = useState<boolean>(false);
	const [topMenuPinned, setTopMenuPinned] = useState<boolean>(true);
	const [showWorksheets, setShowWorksheets] = useState<boolean>(false);
	const [worksheetStore, setWorksheetStore] = useState<WorksheetStores | null>();
	const [activeWorksheetId, setActiveWorksheetId] = useState<string | null | undefined>(null);
	const [baseRoute, setBaseRoute] = useState<string | null>(null);
	const [setting, setSetting] = useState<UISettings | null>();
	const [cssTransition, setCSSTransition] = useState(false);

	const { getSetting } = useLoadUserSettings();

	const location = useLocation();
	const navigate = useNavigate();
	const matches = useMatches();
	const { data } = matches.find(item => decodeURIComponent(item.pathname) === decodeURIComponent(location.pathname)) as { data: RouteData; };
	const { data: rootPathData } = matches.find(item => item.pathname === '/') as { data: RouteData; };

	let ribbons, heading;

	if (data) {
		ribbons = data.ribbons;
		heading = data.heading;
	}

	if (rootPathData?.ribbons) {
		ribbons = [...rootPathData.ribbons, ...(ribbons ?? [])];
	}

	const { isLoading, isAuthenticated, user, loginWithRedirect, getIdTokenClaims, getAccessTokenSilently } = useAuth0();

	const [shouldFetch, setShouldFetch] = useState<boolean>(false);
	const { obxuser } = useLoggedInUser(shouldFetch, {
		revalidateOnFocus: false,
		onSuccess: (user: any, k: any) => {
			setLoggedInUser(user);
			setCookie("userid", user.emailAddress);
			let win: any = window;

			win.progressier.add({
				email: user.emailAddress,
				id: user.userId
			});
		},
	});

	const [dialogHeader, setDialogHeader] = useState<ReactNode | ((props: DialogProps) => ReactNode) | undefined>();
	const [dialogFooter, setDialogFooter] = useState<JSX.Element>();
	const [dialogBody, setDialogBody] = useState<JSX.Element>();
	const [dialogSize, setDialogSize] = useState<DialogSize>();
	const [dialogPosition, setDialogPosition] = useState<DialogPosition>('top');
	const [dialogVisibility, setDialogVisibility] = useState<boolean>(false);
	const [dialogHideHandler, setDialogHideHandler] = useState<() => void>();

	const [loggedInUser, setLoggedInUser] = useState<ProfileResult | undefined>(undefined);
	const [signalRConnected, setSignalRConnected] = useState<boolean>();
	const [signalRApiLocal, setSignalRApiLocal] = useState<SignalRApi>();

	const nodeRef = useRef(null);
	const worksheetRef = useRef(null);
	const dialog = useRef<Dialog>(null);
	const toastRef = useRef<ToastMessageRef>(null);

	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" })
	const app = useRef<HTMLDivElement>(null);
	const toggleState = (force?: boolean) => setShowMenu(force ?? !showMenu);

	const onShowDialog = (event: CustomEvent<IDialogDisplayEventDetails>) => {
		console.log("show dialog", event);

		const { header, footer, body, size, onHide, position = 'top' } = event.detail;

		setDialogVisibility(true);
		setDialogHeader(header);
		setDialogBody(body);
		setDialogFooter(footer);
		setDialogSize(size);
		setDialogPosition(position);
		setDialogHideHandler(() => onHide);
	}


	const onHideDialog = ():void => {
		console.log("Dialog hidden, clean up");
		setDialogVisibility(false);
		dialogHideHandler && dialogHideHandler();
	};

	useEffect(() => {
		setCSSTransition(Boolean(showWorksheets && setting && worksheetStore));
	}, [showWorksheets, setting, worksheetStore]);

	useEffect(() => {
		const onWorksheetPanelDisplay = (event: CustomEvent<SignalRWorksheetPanel>) => {
			setShowWorksheets(event.detail.show);

			if (event.detail.show) {
				setSetting(event.detail.uiSetting);
				setWorksheetStore(event.detail.type);
				setActiveWorksheetId(event.detail.active);
				setBaseRoute(event.detail.baseRoute);
			} else {
				setWorksheetStore(null);
				setActiveWorksheetId(null);
				setBaseRoute(null);
			}
		}

		const onShowToastMessage = (event: CustomEvent<ReplaceFnProps>) => {
			toastRef.current?.replace(event.detail);
		};

		if (isLoading) {
			console.log("Loading!");
		} else if (!isAuthenticated) {
			loginWithRedirect({ appState: { target: window.location.pathname + window.location.search } });
		} else if (isAuthenticated && user) {
			// Get access_token
			getAccessTokenSilently().then(accessToken => {
				setCookie("access_token", accessToken);

			});
			// Get id_token
			getIdTokenClaims().then(idToken => {
				if (!obxuser) {
					axios.interceptors.request.use(
						request => {
							if (request?.headers) {
								request.headers["authorization"] = idToken?.__raw;
							}
							return request;
						}
					)
					setCookie("id_token", idToken?.__raw);
					const win : any = window;
					if (win && win.ReactNativeWebView) {
						win.ReactNativeWebView.postMessage(JSON.stringify({ access_token: idToken?.__raw, type: "access_token" }));
					}
					//  trigger the SWR loading of the OBX user profile
					setShouldFetch(true);
					setSignalRApiLocal(new SignalRApi());
				}
			}).catch(error => {
				console.log("Error setting auth header", error);
			});
			eventBus.on(WorksheetPanelDisplayEvent.SHOW_PANEL, onWorksheetPanelDisplay);
			eventBus.on(WorksheetPanelDisplayEvent.HIDE_PANEL, onWorksheetPanelDisplay);
			eventBus.on(GlobalDialogDisplayEvents.DISPLAY, onShowDialog);
			eventBus.on(GlobalDialogDisplayEvents.HIDE, onHideDialog);
			eventBus.on(
				ApplicationInternalEventTypes.APP_SHOW_TOAST_MESSAGE,
				onShowToastMessage
			);
		}

		return (): void => {
			eventBus.remove(WorksheetPanelDisplayEvent.SHOW_PANEL, onWorksheetPanelDisplay);
			eventBus.remove(WorksheetPanelDisplayEvent.HIDE_PANEL, onWorksheetPanelDisplay);
			eventBus.remove(GlobalDialogDisplayEvents.DISPLAY, onShowDialog);
			eventBus.remove(GlobalDialogDisplayEvents.HIDE, onHideDialog);
			eventBus.remove(
				ApplicationInternalEventTypes.APP_SHOW_TOAST_MESSAGE,
				onShowToastMessage
			);
		};
		// eslint-disable-next-line
	}, [isAuthenticated, isLoading, user]);

	const restartSignalRConnection = useCallback(() => {
		if (signalRApiLocal) {
			// Try to restart, with new hubConnection it sends "$token" instead of "Bearer $token" on retry
			signalRApiLocal.closeConnection();
			signalRApiLocal.hubConnection = signalRApiLocal.initHubConnection();
			signalRApiLocal.restoreEvents();
			signalRApiLocal?.startConnection(state => {
				setSignalRConnected(state === signalR.HubConnectionState.Connected);
			});
		}
	}, [signalRApiLocal])

	// Sometimes when the user logs in to another account `setCookie('userid', ...)` isn't called yet
	// and the old `userid` is sent inside the `SignalRApi.initHubConnection` method.
	// Because of this signalR cannot open the connection - assumes that the user is unauthorized.
	// Let's try to open the connection again once the proper user (and cookie) is set and signalR is still disconnected.
	// (see: https://dev.azure.com/oilbrokerage/OBXchange/_workitems/edit/1357)
	useEffect(() => {
		if (!loggedInUser || !signalRApiLocal) {
			return;
		}

		signalRApiLocal.connectedStateChangedListner(state => {
			setSignalRConnected(state === signalR.HubConnectionState.Connected);

			if (state === signalR.HubConnectionState.Disconnected) {
				restartSignalRConnection();
			}
		});

		// start new connection once user is set and connection is still 'Disconnected'
		if (signalRApiLocal.hubConnection.state === signalR.HubConnectionState.Disconnected) {
			restartSignalRConnection();
		}
	}, [loggedInUser, restartSignalRConnection, signalRApiLocal])

	useEffect(() => {
		setShowMenu(false);

		//	If needed, close the worksheets panel
		const { state } = location
		if (state?.closeWorksheets) {
			eventBus.dispatch(WorksheetPanelDisplayEvent.HIDE_PANEL, { show: false })
		}

	}, [location]);

  // Check first available route for user and navigate to it (optionally store in user settings)
  const navigateToFirstAvailableRoute = useCallback(() => {
    const path = location.pathname;
    const firstAvailable = getFirstAvailableRoute(
      getSetting,
      config.lowerLevelEnvironment,
      isTabletOrMobile,
      obxuser?.assignedSecurityRights
    );

    firstAvailable && navigate(
      firstAvailable.route, // Navigate to main if user has no access to current
      { replace: true, state: { from: path } }
    );
  },[config.lowerLevelEnvironment, getSetting, isTabletOrMobile, location.pathname, navigate, obxuser?.assignedSecurityRights]);


	useEffect(() => {
		if (obxuser && data) {
			const path = location.pathname;

			// console.log('Trying access to:', path, data);

			if (data.accessCode !== SecurityRights._Unprotected && path !== '/') {
				const includes = hasUserAccess(data.accessCode, obxuser?.assignedSecurityRights);
				// If user has no access, add info and redirect to first available
				if (!includes) {
					console.info('No access to: ', path);
					navigateToFirstAvailableRoute();
				}
			} else if (path === '/') {
				const userSetting = getSetting(UISettings.DEFAULT_ROUTE);
				// If path is default ("/") and settings are stored -> Navigate to user default route
				if (userSetting) {
					// If default route is there but Security Rights changed and now logic changed for module custom route
					const modules = getTopMenuRoutes(getSetting, obxuser?.assignedSecurityRights);
					const foundUserSettingRoute = modules.find(item => item.name === userSetting);

					if (foundUserSettingRoute) {
						// If it's mobile view but route is hidden from mobile -> don't redirect to it
						if (isTabletOrMobile && foundUserSettingRoute.hideOnMobile) {
							navigateToFirstAvailableRoute();
						} else {
							navigate(
								foundUserSettingRoute.route,
								{replace: true, state: {from: path}}
							);
						}
					}
				} else {
					// If there is no default route stored -> navigate to first available and store it as default
					navigateToFirstAvailableRoute();
				}
			}
		}
		// eslint-disable-next-line
	}, [data, location, obxuser])

	useEffect(() => {
		const seperator = " | ";
		let path = window.location.pathname.split("/").join(seperator).toUpperCase();

		let title = "OBXchange";
		if ((path.length - seperator.length) > 1) {
			title += ` ${window.location.pathname.split("/").join(" | ").toUpperCase()}`;
		}
		document.title = title;
	});

	if (isLoading || !isAuthenticated || !loggedInUser) {
		return <div className="AppContainer">
			<div className="AppBody">
				<ErrorToast />
				<div className="loader">
					<Loader />
				</div>
			</div>
		</div>
	}

	const renderRibbon = (type: RibbonTypes, index: number) => {
		switch (type) {
			case RibbonTypes.MobileVersionInDevelopment:
				return <MobileVersionInDevelopmentRibbon key={index} />;
			case RibbonTypes.AlphaRelease:
				return <AlphaReleaseRibbon key={index} />;
			case RibbonTypes.NoInternetConnection:
				return <NoInternetConnection key={index} />;
		}
	}

	const prSettings: APIOptions = {
		ripple: true,
		zIndex: {
			overlay: 5000,
		}
	}

	return (
		<PrimeReactProvider value={prSettings}>
			<div
				ref={app}
				className={clsx(
					"app__canvas",
					"grow-to-fill",
					{ 'top-menu--unpinned': !topMenuPinned }
				)}
			>
				{!data?.hideMenu && <TopMenu
					signalRStatus={signalRConnected}
					user={user}
					toggleState={toggleState}
					lowerLevelEnvironment={config.lowerLevelEnvironment}
					topMenuPinned={topMenuPinned}
					setTopMenuPinned={setTopMenuPinned}
					heading={heading}
				/>}
				<CSSTransition
					in={showMenu && isTabletOrMobile}
					nodeRef={nodeRef}
					timeout={260}
					classNames="underlay"
					unmountOnExit>
					<div key={1} ref={nodeRef} className='menu-underlay top-menu--overlay'></div>
				</CSSTransition>
				<div className='AppBody direction--column grow-to-fill'>
					{ribbons &&
						<Ribbons key={2}>
							{ribbons.map((r, index: number) => renderRibbon(r, index))}
						</Ribbons>
					}
					<div className="app-body__content grow-to-fill overflow--hidden">
						{setting && worksheetStore &&
							<CSSTransition
								in={cssTransition}
								unmountOnExit
								timeout={0}
								nodeRef={worksheetRef}
							>
								<div ref={worksheetRef} >
									<AllWorksheets
										key={2}
										activeWorksheetId={activeWorksheetId}
										setActiveWorksheetId={setActiveWorksheetId}
										store={worksheetStore}
										setting={setting}
										currentRoute={baseRoute ?? stripUUID(location.pathname)}
										preventDeleteByNonOwners
									/>
								</div>
							</CSSTransition>
						}
						<div className="module__canvas grow-to-fill">
							<Outlet context={{ signal: signalRApiLocal }} />
							<ErrorToast />
						</div>
					</div>
				</div>
			</div>
			<Dialog
				appendTo={app.current}
				position={dialogPosition}
				ref={dialog}
				className={clsx(
					'p-dialog--margin-less',
					!dialogHeader && 'p-dialog--no-header',
					`p-dialog--${dialogSize}`
				)}
				visible={dialogVisibility}
				showHeader={Boolean(dialogHeader)}
				header={dialogHeader}
				icons={DialogHeaderIcons}
				closable={false}
				footer={dialogFooter}
				onHide={onHideDialog}
			>
				{dialogBody}
			</Dialog>
      <ToastMessage ref={toastRef} />
		</PrimeReactProvider>
	);
}

export const useSignalR = () => useOutletContext<Socket>();

export default App;

import {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  ReactElement,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { Blocker, BlockerFunction } from 'react-router-dom';
import { clsx } from 'clsx';
import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

import Loader from 'components/Loader';
import { useLoggedInUser } from 'components/OBXUser/Services/ProfileHooks';
import { ToastMessageRef, ToastSeverity } from 'components/ToastMessage';

import { deferNextAction } from '../../../CargoTracker/Components/CargoEditWarningDialog';
import { DistributionListCreateRequest } from '../../Models/distribution-list-create-request';
import { distListValidator } from '../../Models/Validators';
import { DistListApiService, useCreateUpdateDistList, useGetDistListById } from '../../Services/DistListService';
import { DistListSignalEventTypes } from '../../Services/SignalRSocket';
import CloseWarningDialog, { DistListWarningDialogEvents } from '../CloseWarningDialog';
import CommonFields from '../CommonFields';

import ListEditEmail from './ListEditEmail';

import useNavigationBlocker from 'helpers/Hooks/NavigationBlocker';
import { stringToEmailAddresses } from 'helpers/Utils/string';
import eventBus from 'server/EventBus';

import type { DistributionList } from 'modules/DistList/Models/distribution-list-response';

interface ListEditProps {
  handleClose: () => void;
  toast: RefObject<ToastMessageRef>;
  setActiveDetailTab: Dispatch<SetStateAction<number>>;
  handleSelection: (value?: DistributionList) => void;
  activeDetailTab?: number;
  activeDlId?: DistributionList['id'];
}

const ListEdit = (props: ListEditProps): ReactElement => {
  const { activeDlId, handleClose, toast, activeDetailTab, setActiveDetailTab, handleSelection } = props;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const [ request, setRequest ] = useState<DistributionListCreateRequest>({} as DistributionListCreateRequest);
  const [ isNewEmailInputVisible, setIsNewEmailInputVisible ] = useState<boolean>(false);
  const [ isValid, setIsValid ] = useState<boolean>(false);
  const [ isValidating, setIsValidating ] = useState<boolean>(false);
  const [ isSavePressed, setIsSavePressed ] = useState<boolean>(false);
  const [ isValidationVisible, setIsValidationVisible ] = useState<boolean>(false);
  const [ isEmailError, setIsEmailError ] = useState<boolean>(false);
  const [ isNewEmailError, setIsNewEmailError ] = useState<boolean>(false);
  const [ failedEmails, setFailedEmails ] = useState<string[]>([]);
  const [ isChanged, setIsChanged ] = useState<boolean>(false);

  const mutateList = (mutation: Partial<DistributionListCreateRequest>): void => {
    setIsChanged(true);
    setRequest(c => ({ ...c, ...mutation }));
  };

  const handleRightColumnClose = async ():Promise<void> => {
    await deferNextAction(DistListWarningDialogEvents.ACTION, null, () => handleClose());
  };

  const { obxuser } = useLoggedInUser();
  const { data, error, isLoading } = useGetDistListById(activeDlId);
  const { trigger, isMutating } = useCreateUpdateDistList();

  useEffect(() => {
    if (activeDlId && data) {
      setRequest({
        id: data.id,
        name: data.name,
        linkedCldd: data.linkedCldd,
        recipients: data.recipients,
        comments: data.comments ?? ''
      });
    } else if (!activeDlId) {
      setRequest(DistListApiService.EmptyRequest);
    }
    setIsChanged(false);
  }, [activeDlId, data]);

  useEffect(() => {
    const validate = async ():Promise<void> => {
      if (isLoading || (activeDlId && !request?.id) || error) {
        return;
      }
      try {
        setIsValidating(true);
        const result = await distListValidator.validateAsync(request, { convert: false });
        if (result) {
          setIsValid(true);
        }
        setIsValidating(false);
      } catch(e) {
        setIsValid(false);
        setIsValidating(false);
      }
    };

    validate();
  }, [activeDlId, error, isLoading, request]);

  useEffect(() => {
    setIsValidationVisible(!!request.id || (!request.id && isSavePressed));
  }, [isSavePressed, isValid, request]);

  const handleSave = useCallback(async ():Promise<void> => {
    setIsSavePressed(true);
    if (isValid && !isValidating && !isLoading && !isEmailError && !isNewEmailError) {
      try {
        const response = await trigger(request);

        // close on update, keep open on create
        if (activeDlId) {
          handleClose();
        } else {
          handleSelection(response);
        }
        toast.current?.replace({
          title: activeDlId ? 'Distribution list updated' : 'Distribution list added',
          message: activeDlId ? 'Distribution list successfully updated' : 'A new distribution list has been successfully added',
          severity: ToastSeverity.SUCCESS
        });

      } catch (e) {
        // Error is handled in the service but catch it here anyway
        // eslint-disable-next-line no-console
        console.log('Something went wrong', e);

      }
    }
  }, [
    isValid,
    isValidating,
    isLoading,
    isEmailError,
    isNewEmailError,
    trigger,
    request,
    handleSelection,
    toast,
    activeDlId,
    handleClose
  ]);

  const handleParseEmail = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>):void => {

    if (e.target.value.trim() === '') { // Hide field if it's empty and lost focus
      setIsNewEmailInputVisible(false);
    }

    const { passed, failed } = stringToEmailAddresses(e.target.value);

    if (passed.length) {
      /** We ALWAYS want to put anything that has passed into our list */
      const concatPassed = [...(request?.recipients ?? []), ...passed];

      mutateList({ recipients: [...new Set(concatPassed)] });

      /** ONLY if there are no errors can we do any UI restoration */
      if (!failed.length) {
        setIsNewEmailInputVisible(false);
        setIsNewEmailError(false);
        e.target.value = "";
        return;
      }
    }

    if (failed.length) {
      setIsNewEmailError(true);
      setFailedEmails(failed);

      /**
       * We're leaving the input field up; but only populated with the items
       * that actually failed. Those that parsed as expected can be removed
       * from the input. So it's more clear to the user what needs to be
       * fixed
      **/
      e.target.value = `${failed.join(', ')}`
    }

    if (e.target.value === '') {  // If field is empty
      setIsNewEmailError(false);
      setFailedEmails([]);
    }

  }, [request.recipients]);


  const addButtonHandler = useCallback(():void => {
    setIsNewEmailInputVisible(true);
  }, []);

  const [ emailText, setEmailText ] = useState<string>();

  const handleKeyUp = (e: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>): void => {

    if (e.key === 'Enter') {
      (e.target as HTMLTextAreaElement).blur();
    }
  };

  const emailErrorMessage = (): ReactElement => (
    <small className="message-invalid">
      {failedEmails.length > 0 ?
        `${failedEmails.length} of the email addresses you tried to add arent valid. Please check these are correct and try again` :
        'Please enter a valid email'}
    </small>
  );

  const inputRequiredMessage = (): ReactElement => (
    <small className="message-invalid">Required field</small>
  );

  // Block navigating to another module when changes are there
  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => currentLocation.pathname !== nextLocation.pathname && isChanged,
    [isChanged]
  );

  useNavigationBlocker(shouldBlock, (blocker: Blocker) => {
    deferNextAction(DistListWarningDialogEvents.ACTION,
      null,
      () => {
        if (typeof blocker.proceed === 'function') {
          try {
            blocker.proceed();
          } catch (e) {
            console.log('Blocker.proceed', e);
          }
        }
      });
  });

  const handleUpdateData = useCallback((event: CustomEvent<DistributionListCreateRequest>): void => {
    if (event.detail.id === activeDlId) {
      setRequest(event.detail);
    }
  }, [activeDlId]);

  useEffect(() => {
    eventBus.on(DistListSignalEventTypes.DIST_LIST_UPDATED, handleUpdateData);

    return () => {
      eventBus.remove(DistListSignalEventTypes.DIST_LIST_UPDATED, handleUpdateData);
    };
  }, [handleUpdateData]);

  useEffect(() => {
    if (isNewEmailInputVisible) {
      textareaRef.current?.focus(); // Autofocus textarea when button is clicked
    }
  }, [isNewEmailInputVisible]);

  return (
    <>
      {isLoading ?
        <div className="grow-to-fill">
          <Loader className="no-background" />
        </div>
        :
        <>
          <div
            ref={containerRef}
            className='grow-to-fill direction--column position--relative overflow--y'
          >
            {isMobile && <Button
              size="small"
              text
              className="plain-text back-button"
              icon={'iconoir-nav-arrow-left icon--small'}
              onClick={handleRightColumnClose}
            >
              Back to list
            </Button>}
            <form
              className="distlist-dl-panel__form position--relative"
            >
              {isMobile && activeDlId &&
                <CommonFields
                  dlName={request.name}
                  activeDetailTab={activeDetailTab}
                  setActiveDetailTab={setActiveDetailTab}
                />}
              {isMobile && !activeDlId && <h1 className="title">Add Distribution List</h1>}
              <div className="form-input__container">
                <label htmlFor="dl-title">Distribution List Title*</label>
                <InputText
                  id="dl-title"
                  className={clsx({
                    'p-invalid':
                      isValidationVisible &&
                      (!request?.name || request?.name?.trim() === ''),
                  })}
                  onChange={(e): void => mutateList({ name: e.target.value })}
                  value={request?.name ?? ''}
                />
                {isValidationVisible &&
                  (!request?.name || request.name.trim() === '') &&
                  inputRequiredMessage()}
              </div>
              <div className="form-input__container">
                <label htmlFor="dl-cldd">CLDD*</label>
                <Dropdown
                  id="dl-cldd"
                  placeholder="Select CLDD"
                  value={request?.linkedCldd ?? null}
                  onChange={(e: DropdownChangeEvent): void =>
                    mutateList({ linkedCldd: e.value })
                  }
                  options={
                    obxuser?.cldds?.map(template => ({
                      label: template.code,
                      value: template.code,
                    })) ?? []
                  }
                  className={clsx({
                    'p-invalid':
                      isValidationVisible &&
                      (!request?.linkedCldd || request.linkedCldd.trim() === ''),
                  })}
                />
                {isValidationVisible &&
                  (!request?.linkedCldd || request.linkedCldd.trim() === '') &&
                  inputRequiredMessage()}
              </div>
              <div className="form-input__container">
                <label htmlFor="dl-email-list">Email List</label>
                {request?.recipients?.length > 0 &&
                  <div className="distlist-dl-email-table-container">
                    {request.recipients.map((email, index) => (
                      <ListEditEmail
                        email={email}
                        index={index}
                        recipients={request.recipients}
                        mutateList={mutateList}
                        setIsEmailError={setIsEmailError}
                        key={`${email}`}
                      />
                    ))}
                  </div>
                }
                <InputTextarea
                  value={emailText}
                  ref={textareaRef}
                  id="dl-email-list"
                  className={clsx('distlist-dl-email-list', {
                    hidden: !isNewEmailInputVisible,
                    'p-invalid': isNewEmailError,
                  })}
                  autoResize
                  onFocus={(e): void => e.target.select()}
                  onBlur={handleParseEmail}
                  onKeyUp={handleKeyUp}
                />
                {isNewEmailError && emailErrorMessage()}
                <Button
                  id="dl-email-list"
                  className={clsx('distlist-add-dl-button', {
                    'not-visible': isNewEmailInputVisible,
                  })}
                  size="small"
                  text
                  icon="iconoir-plus icon--tiny"
                  type="button"
                  onClick={addButtonHandler}
                >
                  Add Recipients
                </Button>
              </div>
              <div className='form-input__container'>
                <label htmlFor="dl-comments">Comments</label>
                <InputTextarea
                  id="dl-comments"
                  key={request.id}
                  className="distlist-dl-comments"
                  autoResize
                  rows={isMobile ? 6 : 2}
                  value={request?.comments ?? ''}
                  onFocus={(e): void => e.target.select()}
                  onChange={(e): void => mutateList({ comments: e.target.value })}
                />
              </div>
            </form>
            <footer className="distlist-dl-panel__footer">
              <Button
                size="small"
                severity="success"
                loading={isMutating}
                onClick={handleSave}
              >
                {activeDlId ? 'Update' : 'Save'}
              </Button>
            </footer>
            <CloseWarningDialog
              containerRef={containerRef}
              handleClose={handleClose}
              isMutating={isMutating}
              isChanged={isChanged}
            />
          </div>
          <Button
            text
            icon="iconoir-xmark icon--tiny p-button-icon-only"
            className={clsx('close-button', { hidden: isMobile })}
            onClick={handleRightColumnClose}
          />
        </>
      }
    </>
  );
};

export default ListEdit;

// TODO: To be updated later with values from BE
export enum ConversationModuleContext
{
  //Sanctions = 0, // TODO: will be used later
  CargoMovements = 1,
  General = 2,
  Reports = 3
}

export const CONV_CONFIG = {
  [ConversationModuleContext.General]: {
    label: 'General',
    name: '',
  },
  [ConversationModuleContext.CargoMovements]: {
    label: 'Cargo Flows',
    name: 'cargo-flows',
  },
  [ConversationModuleContext.Reports]: {
    label: 'Reports',
    name: 'reports',
  },
  /* TODO: will be used later
  [ConversationModuleContext.Sanctions]: {
    label: 'Sanctions',
    name: 'sanctions',
  },
  */
};

export enum MessageRole
{
  User = 0,
  Assistant
}